<template>
  <div class="card m-auto col-12 md:col-5 lg:col-3 flex flex-column align-items-center gap-5">
    <span class="mt-5 text-xl">品牌會員註冊成功</span>
    <span>您的帳號已開通，可以開始累計點數囉！</span>
    <span>【請點選下方按鈕登入會員系統】</span>
    <button class="mt-2 mb-5 p-button p-button-purpply" :onclick="onClick" type="button">會員登入</button>
  </div>
</template>

<script>
export default {
  name: "RegisterSuccessPage",
  methods: {
    onClick() {
      const b = this.$route.query.b ? decodeURI(this.$route.query.b) : null;
      const query = b ? { b } : {};
      this.$router.push({
        name: "login",
        path: "",
        query,
      });
    },
  },
};
</script>